<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="创建版本"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">创建</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="3">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">版本信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="21">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.version">
                        <template slot="prepend">版本号</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.agent_uuid">
                        <template slot="prepend">代理版本</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10" type="flex" align="middle" justify="center">
                  <el-col :span="3">
                    <span>lasted</span>
                  </el-col>
                  <el-col :span="9">
                    <el-radio-group v-model="form.is_current">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="3">
                    <span>平台</span>
                  </el-col>
                  <el-col :span="9">
                    <el-select v-model="form.platform" placeholder="请选择">
                      <el-option
                        v-for="item in platforms"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.download_url">
                        <template slot="prepend">下载地址</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10" type="flex" align="middle" justify="center">
                  <el-col :span="3">
                    <span>浏览器下载</span>
                  </el-col>
                  <el-col :span="9">
                    <el-radio-group v-model="form.is_browser_download">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="3">
                    <span>是否强制更新</span>
                  </el-col>
                  <el-col :span="9">
                    <el-radio-group v-model="form.force">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10" type="flex" align="middle" justify="center">
                  <el-col :span="3">
                    <span>全量更新</span>
                  </el-col>
                  <el-col :span="9">
                    <el-radio-group v-model="form.is_whole_update">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="3">
                    <span></span>
                  </el-col>
                  <el-col :span="9">
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.browser_download_url">
                        <template slot="prepend">浏览器下载地址</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.md5">
                        <template slot="prepend">文件md5值</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                    <el-input
                      type="textarea"
                      style="margin-top:10px"
                      :autosize="{ minRows: 4, maxRows: 30}"
                      placeholder="版本升级备注"
                      :rows="20"
                      v-model="form.comments">
                    </el-input>
                  </el-col>
                </el-row>
              </el-col>
              <!-- 右边结束 -->
            </el-row>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>状态</span>
            </div>
            <p class="p-extra mb5">启用状态</p>
            <el-select v-model="form.status" placeholder="请选择账号状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 添加商品表单结束 -->

  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        form:{},
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
        ],
        platforms:[
          {name:'win32', value:'win32'},
          {name:'darwin', value:'darwin'},
          {name:'Android', value:'android'},
          {name:'Ios', value:'ios'},
        ],
      }
    },
    methods:{
      save(){
        if(!this.form.version || !this.form.platform){
          this.$message({message:'版本号或平台不能为空', type:'error'})
          return;
        }

        apis.appversion.create(this.form).then(res=>{
          if(res.data.code == 1){
            this.$message({message:'创建成功', type:'success'})
            this.$router.push({name:'appversion'})
          }
        }).catch(err=>{
          this.$message({message:err,type:'error'})
          console.log('err',err)
        })
      },
    },
    mounted(){
    },
    computed:{

    },
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>